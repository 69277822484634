import { createStore } from "vuex";

import type { Tag, House, FType } from "@/cutomTypes/index";

const getRandomNumbers = (arr: Tag[], count: number) => {
  const result = new Array(count);
  const len = arr.length;
  const taken = new Set();

  for (let i = 0; i < count; i++) {
    let index;
    do {
      index = Math.floor(Math.random() * len);
    } while (taken.has(index));
    taken.add(index);
    result[i] = arr[index];
  }
  return result;
};

export default createStore({
  state: {
    specifications: [
      { label: "单间", id: "1" },
      { label: "大单间", id: "2" },
      { label: "一室一厅", id: "3" },
      { label: "两室一厅", id: "4" },
      { label: "两室两厅", id: "5" },
      { label: "三室一厅", id: "6" },
      { label: "三室两厅", id: "7" },
    ],
    tags: [
      { label: "阳光房", id: "1" },
      { label: "大阳台", id: "2" },
      { label: "路边房", id: "3" },
      { label: "电梯房", id: "4" },
      { label: "精装修", id: "5" },
      { label: "近地铁", id: "6" },
      { label: "Loft", id: "7" },
      { label: "经济型", id: "8" },
      { label: "ins风", id: "8" },
    ],
    houseList: [
      {
        date: "2024-10-04",
        title: "东圃地铁站豪华装修 免，中，街押一付一 民水电 拎包入住",
        address: "天河 东圃 宦溪堂前新街14号小区",
        price: 1580,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img0.baidu.com/it/u=3556993737,3634738843&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=375",
      },
      {
        date: "2024-10-03",
        title: "城市假日园 精装修大三房 正南向全采光 已配两米大床 可砍价",
        address: "天河 东圃 城市假日园",
        price: 4600,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "http://img2.baidu.com/it/u=1341149362,1418289044&fm=253&app=138&f=JPEG?w=1067&h=800",
      },
      {
        date: "2024-10-02",
        title: "菁品园 精装修 三房两卫 中高层 南向全采光 家私家电齐全",
        address: "天河 东圃  盈彩美居",
        price: 4800,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://pic1.58cdn.com.cn/dwater/fang/big/n_v2ecbc96557f064f32aae278af83d590e0.jpg?srotate=1",
      },
      {
        date: "2024-10-01",
        title: "三溪 美林湖畔 精致两房 电梯房 周边配套 美林天地山姆会员",
        address: "天河 东圃 美林湖畔花园",
        price: 4800,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img2.baidu.com/it/u=3552846865,3292903038&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=666",
      },
      {
        date: "2024-10-04",
        title: "华润万家旁边 地铁口物业 商圈配套完整 工作生活的必备小区",
        address: "天河 东圃 中兴花园",
        price: 1580,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img0.baidu.com/it/u=1888861824,613664017&fm=253&fmt=auto&app=120&f=JPEG?w=600&h=450",
      },
      {
        date: "2024-10-03",
        title: "中兴花园 精装两房 小区绿化高 非常适合居住 价格可谈",
        address: "天河 东圃 中兴花园",
        price: 2900,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://t11.baidu.com/it/u=2578564362,232071390&fm=30&app=106&f=PNG?w=542&h=406&s=F154579127326227187CEDD80300C0B3",
      },
      {
        date: "2024-10-02",
        title: "东圃BRT旁温馨小区安静望花园三房拎包入住居家",
        address: "天河 东圃 旭景家园",
        price: 4000,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img2.baidu.com/it/u=2457389927,1087349799&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=427",
      },
      {
        date: "2024-10-01",
        title: "美林湖畔花园 精装大两房 南向安静望泳池 全新家私家电 可谈",
        address: "天河 东圃 美林湖畔花园",
        price: 1580,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img0.baidu.com/it/u=2793661469,3207887531&fm=253&fmt=auto?w=500&h=333",
      },
      {
        date: "2024-10-02",
        title: "中兴花园二期 两房家电齐 保养好",
        address: "天河 东圃 中兴花园",
        price: 4200,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://pic1.ajkimg.com/display/anjuke/e9209025618a4413de28b887e9410bea/600x450c.jpg?t=1&srotate=1",
      },
      {
        date: "2024-10-01",
        title: "东圃 优志小区 兰亭盛荟 精装大三房 首此出租 密码看房",
        address: "天河 东圃 兰亭盛荟",
        price: 6500,
        videoUrl:
          "https://wosmedia2.anjukestatic.com/mLNJiHgFeDmP/zftransform/df14b561-a57b-4a58-bcd5-60b74b826619.1727608326736.51544-51717197.mp4",
        coverUrl:
          "https://img0.baidu.com/it/u=715735461,2691282625&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500",
      },
    ],
  },
  getters: {
    GetSpecifications: (state): FType[] => {
      return state.specifications;
    },
    GetTags: (state): Tag[] => {
      return state.tags;
    },
    GetHouseList: (state, getters): House[] => {
      const specifications = getters.GetSpecifications;
      const tags = getters.GetTags;
      const houseList = state.houseList.map((m: House): House => {
        m.type = getRandomNumbers(specifications, 1)[0];
        m.tags = getRandomNumbers(tags, 4);
        return m;
      });
      return houseList;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
