import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ElMessage } from "element-plus";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    redirect: "/houseList",
    children: [
      {
        path: "/houseList",
        name: "HouseList",
        meta: { menuName: "房源列表" },
        component: () => import("@/views/HouseList/HouseListView.vue"),
      },
      {
        path: "/specifications",
        name: "Specifications",
        meta: { menuName: "规格列表" },
        component: () =>
          import("@/views/Specifications/SpecificationsView.vue"),
      },
      {
        path: "/tags",
        name: "Tags",
        meta: { menuName: "标签列表" },
        component: () => import("@/views/Tags/TagsView.vue"),
      },
    ],
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/LoginView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    // 登录或者注册才可以往下进行
    next();
  } else {
    // 获取 token
    const token = sessionStorage.getItem("Authorization");
    // token 不存在
    if (token === null || token === "") {
      ElMessage.error("您还没有登录，请先登录");
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
